<template>
	<div class="sales-partner-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Sales Partner" filter="name" sort="name"
		               v-bind:order="1"
		               v-bind:fields="fields"
		               v-bind:data-array="salesPartners"
		               v-on:add-new="addSalesPartner"
		               v-on:row-clicked="navigate($event)">
        </table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";

export default {
	name: "SalesPartnerTable",

	components: {
		TableSummary,
	},

	data() {
		return {
			fields: [
				{ display: "Partner No.", key: "partnerNo", classBinding: ["f-2"] },
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Type", key: "type", classBinding: [] },
				{ display: "Contact", key: "contacts", classBinding: ["f-2"] },
				{ display: "Phone", key: "phone", classBinding: [] },
				{ display: "Email", key: "email", classBinding: ["f-2"] },
				{ display: "Handler", key: "handler", classBinding: ["f-2"] },
				{ display: "Address", key: "address", classBinding: ["f-5"] },
			],
		}
	},

	computed: {
		salesPartners() {
			return this.$store.state.contents.salesPartners.map(partner => {
				const obj = {...partner};
				const handler = this.$store.state.contents.staffs.find(({ _id }) => _id === partner.handler);
				obj.handler = handler ? `${handler.preferredName} ${handler.lastName}` : "N/A";
				if (partner.contacts.length > 0) {
					obj.contacts = partner.contacts[0].name ? partner.contacts[0].name : "N/A";
					obj.phone = partner.contacts[0].phone ? partner.contacts[0].phone : "N/A";
					obj.email = partner.contacts[0].email ? partner.contacts[0].email : "N/A";
				}
				return obj;
			});
		},
	},

	methods: {
		async addSalesPartner() {
			await this.$router.push("/sales/partner/add");
		},

		async navigate(dataObj) {
			await this.$router.push(`/sales/partner/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
